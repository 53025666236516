.ihm-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    margin-bottom: 5vh; 
}

.ihm-wrapper .content {
    padding: 1rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    border-radius: .25rem;
    width: 75%;
}

.ihm-wrapper .srv-validation-message {
    color: darkred;
    font-size: 0.9em;
    margin-top: 2px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .ihm-wrapper {
        display: block;
        margin-top: unset;
        margin-bottom: unset;
    }
    
    .ihm-wrapper .content {
        padding: 0.5em;
        width: 100%;
        box-shadow: none;
    }
}